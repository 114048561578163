/** @format */

import {
  BindingEngine,
  customElement,
  computedFrom,
  inject,
  bindable,
  DOM
} from 'aurelia-framework';
import uiService from 'services/uiService';

import './slide-nav.scss';

@inject(BindingEngine, DOM.Element)
@customElement('slide-nav')
export class SlideNav {
  @bindable router;

  constructor(_BindingEngine, _DOMElement) {
    this.el = _DOMElement;
    this.uiService = uiService;

    this.bindingEngine = _BindingEngine;
    this.menuItems = [];
    this.goToRoute = this.goToRoute.bind(this);
  }

  @computedFrom('uiService.theme')
  get theme() {
    return this.uiService.theme && this.uiService.theme;
  }

  @computedFrom('uiService.userSettings', 'uiService.theme')  
  get showPoweredBy() {
    const { isWhiteLabel } = this.uiService.userSettings || {};
    const { isCustom } = this.uiService.theme || {};

    return isCustom && !isWhiteLabel;
  }

  attached() {
    this.bindingEngine
      .propertyObserver(this.uiService, 'isSlideNavOpen')
      .subscribe(this.isOpenChanged.bind(this));
    this.toggleMenu(this.uiService.isSlideNavOpen);
  }

  onCloseNav() {
    this.uiService.isSlideNavOpen = false;
  }

  isOpenChanged(isOpen) {
    this.toggleMenu(isOpen);
  }

  toggleMenu(isOpen) {
    //TODO REFACTOR THIS INTO AN HELPER LIBRARY, OR FIND ANOTHER WAY TO DO THIS!
    if (this.isOpen !== isOpen) {
      let action = !isOpen ? 'add' : 'remove';
      let classOrder = !isOpen ? ['closed', 'hide'] : ['hide', 'closed'];
      let delay = !isOpen ? 500 : 0;

      this.el.classList[action](classOrder[0]);
      setTimeout(() => {
        this.el.classList[action](classOrder[1]);
        this.isOpen = open;
      }, delay);
    }
  }

  goToRoute(name) {
    this.onCloseNav();
    this.router.navigateToRoute(name);
  }
}
