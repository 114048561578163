/** @format */

import { customElement, inject, bindable, DOM } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import eventService, { EventsList } from 'services/eventService';

import './nav-bar.scss';

@inject(Router, DOM.Element)
@customElement('nav-bar')
export class NavBar {
  @bindable router;

  constructor(router) {
    this.router = router;
  }

  attached() {
    eventService.subscribe(EventsList.AppRouterChanged, this.onRouteChanged);
  }

  detached() {
    eventService.subscribe(EventsList.AppRouterChanged, this.onRouteChanged);
  }

  onRouteChanged = name => {
    this.activeRoute = name;
  };
}
