/** @format */

import { inject } from 'aurelia-framework';
import uiService from 'services/uiService';

import { DialogController } from 'aurelia-dialog';

@inject(DialogController)
export class NavImpersonateDialog {
  constructor(_DialogController) {
    this.dialogController = _DialogController;
    this.uiService = uiService;

    this.selectedUser = null;
    this.loading = false;

    this.onUserSelect = this.onUserSelect.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onRevert = this.onRevert.bind(this);
  }

  activate() {
    this.impersonation = this.uiService.impersonation;
  }

  onUserSelect(user) {
    this.selectedUser = user;
  }

  onConfirm() {
    if (this.selectedUser) {
      this.uiService.impersonate(this.selectedUser.username);

      this.selectedUser = false;
      this.loading = false;
    }
  }

  onRevert() {
    this.uiService.impersonate(null);
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
