import {
  bindable
} from 'aurelia-framework';

import './search-autocomplete.scss';
export class SearchAutocomplete {
  @bindable value;
  @bindable items;
  @bindable label;

  @bindable onValueChange;
  @bindable onItemSelect;

  constructor() {
    this.value = null;
    this.items = [];
  }

  onInputClick() {
    this.mode.action();
  }

  valueChanged(value) {
    if (!value) {
      this.value = null;
      this.items = [];
    }
    if (this.onValueChange) {
      this.onValueChange(value);
    }
    if (!value && this.onItemSelect) {
      this.onItemSelect(null);
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 13) {
      this.autoSelect();
    }
    return true;
  }

  _onItemSelect(item) {
    if (this.onItemSelect) {
      this.onItemSelect(item);
    }
  }

  autoSelect() {
    if (!!this.value && this.items.length) {
      this._onItemSelect(this.items[0])
    }
  }
}
