/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  Router
} from 'aurelia-router';
import {
  DialogService
} from 'aurelia-dialog';

import alertsService from 'services/api/alertsService';
import eventService, {
  EventsList
} from 'services/eventService';

import {
  AlertDetails
} from 'components/alert-details/alert-details';

import './nav-notifications.scss';

const MAX_LIST_LENGTH = 20;

export class NavNotifications {
  static inject() {
    return [Router, DialogService];
  }
  constructor(_Router, _DialogService) {
    this.router = _Router;
    this.dialogService = _DialogService;
    this.alertsService = alertsService;

    this.showDropdown = false;
    this.alerts = [];
    this.loading = true;
    this.processing = false;
  }

  attached() {
    this.loadRecentAlerts();

    eventService.subscribe(
      EventsList.SignalrbroadcastAlert,
      this.onAlertBroadcast
    );
    document.addEventListener('click', this.onDocumentClick.bind(this));
    //
    this.windowTitle = window.document.title;
  }

  detached() {
    eventService.unsubscribe(
      EventsList.SignalrbroadcastAlert,
      this.onAlertBroadcast
    );
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.showDropdown) {
      this.onToggleDropdown(e);
    }
  }

  loadRecentAlerts() {
    if (!this.lastUpdated || Date.now() - this.lastUpdated > 10 * 1000) {
      this.loading = true;
      this.alertsService.getOccurrencesSummary().then(({
        alerts,
        count
      }) => {
        this.alerts = alerts;
        this.updateBadge(count);

        this.lastUpdated = Date.now();
        this.loading = false;
      });
    }
  }

  onAlertBroadcast = alert => {
    if (this.number < MAX_LIST_LENGTH) {
      this.alerts.unshift(alert);
    }
    this.updateBadge(this.number + 1);
  };

  @computedFrom('number')
  get hasBadge() {
    return this.number > 0;
  }

  @computedFrom('number')
  get badgeNumber() {
    return this.number;
  }

  onToggleDropdown(e, toggle = true) {
    if (toggle) this.toggleDropdown();

    e.stopImmediatePropagation();
    if (this.showDropdown && toggle) {
      var divs = document.querySelectorAll('.clickable-dropdown.active');
      for (var i = 0; i < divs.length; ++i) {
        divs[i].click();
      };
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    if (this.showDropdown) {
      this.loadRecentAlerts();
    }
  }

  onAlertClick(e, index, alert) {
    let action =
      e.target.dataset.action || e.target.parentElement.dataset.action;
    if (action) {
      //
      let afterProcessed = () => {
        this.alerts.splice(index, 1);
        this.updateBadge(this.number - 1);

        if (!this.alerts.length) {
          this.toggleDropdown();
        }
        this.processing = false;
      };

      //
      let markAsProcessed = () => {
        alert.processed = true;
        return this.alertsService.updateOccurrence(alert);
      };

      this.processing = true;
      this.removeAnimated(index, this.alerts.length < 2 ? 0 : 500)
        .then(markAsProcessed)
        .then(afterProcessed);
    } else {
      this.openDetails(alert);
      this.toggleDropdown();
    }

    e.stopImmediatePropagation();
  }

  removeAnimated(index, ms = 500) {
    return new Promise(res => {
      if (this.refList) {
        let elem = this.refList.querySelectorAll('li.collection-item')[index];
        if (elem) {
          elem.classList.add('removing');
          setTimeout(res, ms);
        }
        return;
      }
      res();
    });
  }

  updateBadge(number) {
    this.number = number;

    //todo better way to do this?
    window.document.title = `${this.number ? `(${this.number})` : ''} ${
      this.windowTitle
    }`;
  }

  openDetails(alert) {
    // this.dialogService.open({viewModel: NavAlertDetails, model: alert})
    this.dialogService.open({
      viewModel: AlertDetails,
      model: alert
    });
  }

  onShowAll() {
    this.toggleDropdown();
    this.router.navigate('alerts');
  }

  onProcessAll(e) {
    this.processing = true;
    this.alertsService.processAllOccurrences().then(() => {
      this.alerts = [];
      this.updateBadge(0);
      this.processing = false;
    });

    e.stopImmediatePropagation();
  }
}
