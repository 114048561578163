/** @format */

import {
  customElement,
  inject,
  DOM
} from 'aurelia-framework';
import userService from 'services/api/userService';
import uiService from 'services/uiService';
import {
  apiConfig
} from 'envconfigs/apiConfig';
import {
  Router
} from 'aurelia-router';
import {
  DialogService
} from 'aurelia-dialog';
import {
  NavProfileDialog
} from './dialogs/nav-profile-dialog/nav-profile-dialog';
import {
  NavImpersonateDialog
} from './dialogs/nav-impersonate-dialog/nav-impersonate-dialog';
import {
  NavSupportDialog
} from './dialogs/nav-support-dialog/nav-support-dialog';

import {
  FeedbackDialog
} from 'components/feedback-dialog/feedback-dialog';
import {
  WhatsNewDialog
} from 'components/whats-new-dialog/whats-new-dialog';

PLATFORM.moduleName('./dialogs/nav-profile-dialog/nav-profile-dialog');
PLATFORM.moduleName('./dialogs/nav-impersonate-dialog/nav-impersonate-dialog');
PLATFORM.moduleName('./dialogs/nav-support-dialog/nav-support-dialog');
PLATFORM.moduleName('components/feedback-dialog/feedback-dialog');
PLATFORM.moduleName('components/whats-new-dialog/whats-new-dialog');

import './nav-profile.scss';

const MENU_DIALOGS = {
  profile: NavProfileDialog,
  impersonation: NavImpersonateDialog,
  feedback: FeedbackDialog,
  support: NavSupportDialog,
  whatsnew: WhatsNewDialog
};

@inject(DOM.Element, DialogService, Router)
@customElement('nav-profile')
export class NavProfile {
  fonixAccountDomain;

  constructor(DOMElement, _DialogService, _Router) {
    this.userService = userService;
    this.uiService = uiService;
    this.dialogService = _DialogService;
    this.router = _Router;

    this.showDropdown = false;
    this.canImpersonate = true;
    this.fonixAccountDomain = apiConfig.fonixAccount;
    this.onItemClick = this.onItemClick.bind(this);
    this.onRouteClick = this.onRouteClick.bind(this);
  }

  attached() {
    document.addEventListener('click', this.onDocumentClick.bind(this));

    this.userService.getSelf().then(user => {
      this.user = user;
      let allowUser = user.isReseller || user.isSuperAdmin;
      this.canImpersonate = this.canImpersonate || allowUser;
    });

    this.canImpersonate = this.uiService.impersonation;
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.showDropdown) {
      this.onToggleDropdown(e);
    }
  }

  onToggleDropdown(e, toggle = true) {
    if (toggle) this.toggleDropdown();

    e.stopImmediatePropagation();
    if (this.showDropdown && toggle) {
      var divs = document.querySelectorAll('.clickable-dropdown.active');
      for (var i = 0; i < divs.length; ++i) {
        divs[i].click();
      };
    }
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  onItemClick(e, name) {
    this.toggleDropdown();

    if (MENU_DIALOGS[name]) {
      this.openDialog(MENU_DIALOGS[name]);
    } else {
      this.router.navigateToRoute(name)
    }
  }

  onRouteClick(e, route = null, target = '') {
    this.toggleDropdown();

    if (route) {
      window.open(route, target);
      return;
    }
  }

  openDialog(viewModel) {
    this.dialogService.open({
      viewModel: viewModel,
      model: this.user
    });
  }
}
