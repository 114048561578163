/** @format */

import { inject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import uiService from 'services/uiService';

import './side-bar.scss';

@inject(Router)
export class SideBar {
  constructor(_Router) {
    this.router = _Router;
    this.uiService = uiService;
    this.menus = [];
  }

  @computedFrom('uiService.theme')
  get cssStyle() {
    if (this.uiService.theme) {
      const { backgroundColor, textColor } = this.uiService.theme;
      const theme = {
        backgroundColor,
        color: textColor
      };
      return theme;
    }
  }

  attached() {
    this.navItems = this.router.navigation.filter(x => x.config.sidebar);
  }

  onMenuClick() {
    this.uiService.isSlideNavOpen = true;
  }
}
