/** @format */

import { inject, computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import userService from 'services/api/userService';

import './nav-support-dialog.scss';

@inject(DialogController)
export class NavSupportDialog {
  constructor(_DialogController) {
    this.dialogController = _DialogController;

    this.name = '';
    this.contacts = null;

    this.loading = true;

    userService.getSelf().then(u => {
      this.name = u.accountName;
      this.contacts = u.accountSettings.supportContacts;
      this.loading = false;
    });
  }

  @computedFrom('contacts')
  get address() {
    if (this.contacts && this.contacts.address) {
      return this.contacts.address.replace(/[\r\n]+/g, '<br/>');
    }
  }

  onClose() {
    this.dialogController.cancel();
  }
}
