/** @format */

import {
  NewInstance
} from 'aurelia-framework';
import userService from 'services/api/userService';

import {
  ASSET_NAME_FORMATS
} from 'services/api/assetsService';

import {
  DialogController
} from 'aurelia-dialog';
import {
  ValidationRules,
  ValidationController,
  ValidationMessageProvider
} from 'aurelia-validation';

import './nav-profile-dialog.scss';

/**
 *
 * TODO: Merge this dialog with manage-user-details dialog!!
 * Lots of repeated code and logic.
 */

export class NavProfileDialog {
  static inject() {
    return [DialogController, NewInstance.of(ValidationController)];
  }
  constructor(_DialogController, _ValidationController) {
    this.dialogController = _DialogController;

    this.validationController = _ValidationController;

    this.userService = userService;

    this.user = null;
    this.loading = false;
    this.errorMessage = null;

    this.assetNameFormats = ASSET_NAME_FORMATS;

    this.rules = ValidationRules.rules;

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  activate(user) {
    this.errorMessage = null;
    this.user = user;
    this.editPreferences = Object.assign({}, user.preferences);
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      this.user.preferences = this.editPreferences;
      return this.userService
        .updateSelf(this.user)
        .then(_user => {
          this.loading = false;
          this.user = null;
          this.dialogController.ok(_user);
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.user,
        rules: this.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
